/*
 * @Author: yangzonglong
 * @Date: 2019-08-22 17:11:34
 * @version: v1.0.0
 * @Descripttion: 
 * @LastEditors: yangzonglong
 * @LastEditTime: 2019-12-17 16:36:47
 * @Auditor: 
 */
import React, { Suspense, useEffect } from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import pages from '@configs/pages';
import ReactLoading from 'react-loading';
import store from '@redux/store';
import history from './browserHistory';
import WelinkLayout from '@auxiliarys/welinkLayout/WelinkLayout';
import { PACKAGE_NAME } from '@package';
import { baseRouters } from '@configs/pages';
import server from '@auxiliarys/units/server';
import Qs from 'query-string';
import { message, Modal } from 'antd';
import i18n from './../i18n';
import './app.scss';
// require('./../mock/mock');

const DEV = process.env.REACT_APP_ENV !== 'dev';
const noPathAuth = ['/user/login', '/user/changePsd'];

const query = Qs.parseUrl(window.location.href).query,
    token = query.token, redirect = query.redirect as string | null;

async function getUserInToken(token: string) {
    try {
        const user = await server('/bk/tokenLogin', { token });
        return user;
    } catch (error) {
        const _err = error as any;
        message.error(_err.message)
    }
}

const App = () => {
    useEffect(() => {
        if (token) {
            getUserInToken(token as string).then(res => {
                if (res) {
                    let menuList: Record<string, any>[] = res.menuList
                    window.sessionStorage.userInfo = JSON.stringify(res)
                    let authRouter = {};
                    menuList && menuList.forEach(item => {
                        item.menu && item.menu.forEach((element: Record<string, any>) => {
                            element.children && element.children.forEach((v: Record<string, any>) => {
                                v.functionUrl = "/" + v.functionUrl.split(".").join("/");
                                authRouter[v.functionUrl] = true;
                            })
                        })
                    })
                    window.sessionStorage.roleData = JSON.stringify(menuList);
                    window.sessionStorage.authRouter = JSON.stringify(authRouter);
                    window.sessionStorage.isLoggedin = 'true';
                    window.sessionStorage.uname = res.name;

                    if(redirect) {
                        window.location.href = `/navbarx?original_url=${redirect}`
                    }else {
                        window.location.href = '/navbarx/main'
                    }
                }
            })
        }
    }, []);

    useEffect(() => {
        let channel: BroadcastChannel;
        const fn = (data: MessageEvent) => {
        const userInfo = sessionStorage.userInfo ? JSON.parse(sessionStorage.userInfo) : {};
          // 登录用户不一致
          if(data.data !== userInfo.id) {
            if(channel) channel.removeEventListener('message', fn);
            if(!sessionStorage.userInfo) return; // 超时登出会清空，这里需要判断下 避免两次弹窗
            window.sessionStorage.clear();
            Modal.confirm({
              title: i18n.t('note'),
              keyboard: false,
              maskClosable: false,
              content: i18n.t('multipleAccountTip'),
              cancelButtonProps: { style: { display: 'none' } },
              onOk: async () => {
                // await server('/bk/logout', {});
                window.sessionStorage.clear();
                window.location.reload();
              }
            })
          }
        }
        if((window as any).BroadcastChannel) {
          channel = new BroadcastChannel('MSG');
          channel.addEventListener('message', fn);
        }
        return () => {
          if(channel) channel.removeEventListener('message', fn)
        }
      }, []);

    return (
        !token ? (
            <Provider store={store}>
                <Router history={history}>
                    <Suspense
                        fallback={<ReactLoading />}>
                        <WelinkLayout
                            noPathAuth={noPathAuth}
                            hideLayout
                            dev={DEV}
                            history={history}
                            menus={baseRouters}
                            appName={PACKAGE_NAME.toLocaleUpperCase()}
                            appId={PACKAGE_NAME}>
                            {pages}
                        </WelinkLayout>
                    </Suspense>
                </Router>
            </Provider>
        ) : <p>loading...</p>
    )
}

export default App;